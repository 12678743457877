<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                    <span style="color: white">
                        {{ msg }}
                    </span>
                </v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-app id="inspire">
            <v-img height="100vh" src="../../assets/Images/logo2.png">
                <v-layout wrap justify-center fill-height>
                    <v-flex xs12 align-self-center>
                        <v-layout wrap justify-center>
                            <v-flex xs7>
                                <v-layout wrap justify-center>
                                    <v-flex xs7>
                                        <v-card>
                                            <v-layout wrap justify-center>
                                                <v-flex xs3 pt-5>
                                                    <v-img height="60px" contain
                                                        src="../../assets/Images/blacklogo.png"></v-img>
                                                </v-flex>
                                                <v-flex xs10 text-center pt-2 class="mainfont">
                                                    <span style="font-size: 22px;">Verify Phone Number</span>
                                                </v-flex>
                                                <v-flex xs10 pt-6>
                                                    <v-text-field color="black" outlined background-color="white" dense
                                                        label="OTP" type="number" v-model="otp" class="inputPrice"
                                                        hide-details>
                                                    </v-text-field>
                                                </v-flex>
                                                <v-flex pt-6 xs10>
                                                    <v-btn class="no-uppercase mainfont" dark block color="#314755"
                                                        @click="register">
                                                        <span>
                                                            Submit
                                                            &nbsp;
                                                        </span>
                                                        <v-icon color="white">
                                                            mdi-arrow-right
                                                        </v-icon>
                                                    </v-btn>


                                                </v-flex>
                                                <v-flex @click="$router.push('/')" style="cursor: pointer;" pb-8
                                                    class="mainfont" text-center pt-3 xs10>
                                                    <span>Already a user? click here to Login</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-img>
        </v-app>
    </div>
</template>
  
<script>
import axios from "axios";
import store from "./../../store";
export default {
    data() {
        return {
            ServerError: false,
            showSnackBar: false,
            timeout: 5000,
            msg: null,
            otp: null,
            id: this.$route.query.userId,
        };
    },
    methods: {
        register() {
            axios({
                method: "POST",
                url: "/adduser/verify-otp",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    otp: this.otp,
                    userid: this.id,
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        setTimeout(() => {
                            this.$router.push({ path: "/" });
                        }, 3000);
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.error(err);
                });
        },
        login() {
            axios({
                method: "post",
                url: "/login/",
                data: {
                    username: this.username,
                    password: this.password,
                },
            })
                .then((response) => {
                    if (response.data.status) {
                        store.commit("loginUser", response.data.token);
                        var uType = response.data.uType;
                        var path = response.data.path;
                        localStorage.setItem("uType", uType);
                        localStorage.setItem("path", path);
                        if (uType === 777) {
                            console.log("hghbg")
                            this.$router.push({ path: "/DashboardPage" });
                        } else {
                            this.$router.push({ path: "/divisiondashboard" });
                        }
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    store.commit("appLoading", false);
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
<style>
.no-spinners {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>       
  